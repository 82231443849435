<template>
  <div>
    <h1>Mijn dossiers</h1>
    <DataTable :value="dossiers.data" :lazy="true" :paginator="true" :rows="10" ref="dt" :totalRecords="dossiers.total"
      :loading="loading" @page="onPage($event)" @sort="onSort($event)" responsiveLayout="scroll">
      <Column field="status" header="Status" :sortable="true">
        <template #body="slotProps">
          {{slotProps.data.status.replace('new', 'nieuw').replace('paid', 'betaald').replace('approved', 'geaccepteerd').replace('signed', 'ondertekend')}}
        </template>
      </Column>
      <Column field="dossier_number" header="Aanvraag nummer" :sortable="true"></Column>

      <Column field="contact" header="Koopsom">
        <template #body="slotProps">
          {{formatCurrency(totalPension(slotProps.data.pension_fund_history), '€', 0)}} <br/>
        </template>
      </Column>
      <Column header="Fiscale Herkomst" :sortable="false">
        <template #body="slotProps">
          {{slotProps.data.pension_fund_history[0] ? slotProps.data.pension_fund_history[0].origin : '-'}}
        </template>
      </Column>
      <Column field="pension_type" header="Type" :sortable="true">
        <template #body="slotProps">
          {{slotProps.data.pension_type ? slotProps.data.pension_type.replace(/^./, slotProps.data.pension_type[0].toUpperCase()).replace('Hooglaag', 'Hoog/Laag') : ""}}
          <span v-show="slotProps.data.pension_type == 'hooglaag'">&nbsp;{{slotProps.data.pension_type_high_years}}</span>
        </template>
      </Column>

      <Column field="created_at" header="Aanmaak datum" :sortable="true">
        <template #body="slotProps">
          {{formatDate(slotProps.data.created_at, 'shortDate')}}
        </template>
      </Column>
      <Column field="start_date_payroll" header="Start datum" :sortable="true">
        <template #body="slotProps">
          {{formatDate(slotProps.data.start_date_payroll, 'shortDate')}}
        </template>
      </Column>
      <Column field="payroll_terms" header="Termijn" :sortable="true">
        <template #body="slotProps">
          {{formatMonthPeriod(slotProps.data.payroll_terms)}}
        </template>
      </Column>
      <Column field="id" header="Acties" :sortable="true">
        <template #body="slotProps">
          <router-link :to="`/me/dossiers/${slotProps.data.id}/home`"> Bekijk </router-link>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
  import dossierservice from '@/services/contact/DossierService'
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';

  export default {
    name: 'MyDossiers',
    components: {
      DataTable,
      Column
    },
    data() {
      return {
        dossiers: {
          'data': [],
          'total': 0
        },
        loading: true,
        lazyParams: {},
        page: 1
      }
    },
    methods: {
      getDossiers() {
        this.loading = true;
        dossierservice.getDossiers(this.page, {
          lazyEvent: JSON.stringify(this.lazyParams)
        }).then(response => {
          this.dossiers = response.data;
          this.loading = false;
          if (response.data.total == 1) {
            this.$router.push({
              name: "myDossier",
              params: {
                dossierId: response.data.data[0].id,
                stage: 'quote'
              }
            })
          }
        }).catch(error => {
          this.$toast.error('Failed to retreive dossiers')
        })
      },
      onPage(event) {
        this.page = event.page + 1
        this.getDossiers();
      },
      onSort(event) {
        this.lazyParams = event;
        this.getDossiers();
      },
      totalPension(pensionFundHistory){
        let total = [];
        Object.entries(pensionFundHistory).forEach(([key, val]) => {
            total.push(val.fund)
        });
        return total.reduce(function(total, num){ return parseFloat(total) + parseFloat(num) }, 0);
      }
    },
    mounted() {
      this.lazyParams = {
        first: 0,
        rows: this.$refs.dt.rows,
        sortField: 'created_at',
        sortOrder: 2,
      };
      this.getDossiers()
    }
  }
</script>